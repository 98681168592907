<template>
  <div class="overflow-hidden">
    <v-app>
      <OnboardingHeader />
      <v-row v-if="loading" class="justify-center my-auto">
        <v-progress-circular
          :size="70"
          color="purple"
          :width="7"
          indeterminate
        />
      </v-row>
      <nuxt v-else />
      <OnboardingFooter />
      <Snackbar />
    </v-app>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import * as Sentry from '@sentry/browser';
import OnboardingHeader from '~/components/Header/OnboardingHeader.vue';
import OnboardingFooter from '~/components/Footer/OnboardingFooter.vue';
import { logOut } from '~/plugins/utils';
import { getUserStatus, getNotificationObjData } from '~/plugins/apiFunctions';
import Snackbar from '~/components/Snackbar.vue';
import { PRODUCT_CODES } from '~/constants';

export default {
  components: {
    OnboardingHeader,
    OnboardingFooter,
    Snackbar
  },
  data() {
    return {
      loading: true
    };
  },
  async mounted() {
    const that = this;
    let authFlag = false;
    // if (
    //   !this.$router.history.current.fullPath.includes('login') &&
    //   !this.$router.history.current.fullPath.includes('success') &&
    //   !this.$router.history.current.fullPath.includes('personal-info')
    // ) {
    //   window.addEventListener('beforeunload', function(e) {
    //     e.preventDefault();
    //     e.returnValue = '';
    //     return '';
    //   });
    // }
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user && !authFlag) {
        const userData = await getUserStatus(user);
        if (userData) {
          if (
            (userData.suezuser && this.$productName !== PRODUCT_CODES.suez) ||
            (userData.pensionbeeuser &&
              this.$productName !== PRODUCT_CODES.pensionbee) ||
            (userData.avcwiseuser &&
              this.$productName !== PRODUCT_CODES.avcwise)
          ) {
            return;
          }
          if (
            !user.emailVerified &&
            !userData.pensionbeeuser &&
            !userData.suezuser &&
            !userData.avcwiseuser &&
            !userData.firefighteruser &&
            !userData.pshuser &&
            this.$productName !== PRODUCT_CODES.suez &&
            this.$productName !== PRODUCT_CODES.pensionbee &&
            this.$productName !== PRODUCT_CODES.avcwise &&
            this.$productName !== PRODUCT_CODES.firefighter &&
            this.$productName !== PRODUCT_CODES.psh
          ) {
            if (
              !this.$router.history.current.fullPath.endsWith('verify-email')
            ) {
              this.$router.push({
                path: '/verify-email',
                name: 'verify-email'
              });
            }
            that.$store.commit('authUser', true);
            that.loading = false;
            return;
          }

          if (
            user.emailVerified &&
            (!userData.personalInfo.firstName ||
              !userData.personalInfo.lastName ||
              !userData.personalInfo.ur_dob)
          ) {
            this.$router.push({
              path: '/onboarding/personal-info',
              name: 'onboarding-personal-info'
            });
          }
          // if (
          //   this.$router.history.current.fullPath.endsWith('personal-info') ||
          //   this.$router.history.current.fullPath.endsWith('login') ||
          //   this.$router.history.current.fullPath.endsWith('login-error') ||
          //   this.$router.history.current.fullPath.endsWith('reset-password')
          // ) {
          //   this.$router.push({
          //     path: '/dashboard/landing',
          //     name: 'dashboard-landing'
          //   });
          //   that.loading = false;
          //   return;
          // }

          authFlag = true;
          if (
            this.$store.state.user.pensionbeeuser &&
            (this.$router.history.current.fullPath.endsWith('personal-info') ||
              this.$router.history.current.fullPath.endsWith('login') ||
              this.$router.history.current.fullPath.endsWith('login-error') ||
              this.$router.history.current.fullPath.endsWith('reset-password'))
          ) {
            this.$router.push({
              path: '/dashboard/landing',
              name: 'dashboard-landing'
            });
            that.loading = false;
            return;
          }
          // set other objects in the state like personal info and pensionhistory
          try {
            that.$store.commit('updateDocumentId', userData.documentId);
            that.$store.commit('updatePersonalInfo', userData.personalInfo);

            if (userData.pensionbeeuser) {
              that.$store.commit(
                'updatePensionBeeStatus',
                userData.pensionbeeuser
              );
              that.$store.commit(
                'updatePensionBeeInfo',
                userData.pensionBeeInfo
              );
              that.$store.commit(
                'updateShortlistedFund',
                userData.shortlistedFund
              );
            }

            if (userData.avcwiseuser) {
              that.$store.commit('updateTourStatus', userData.tourCompleted);
              that.$store.commit(
                'updateAVCWiseSelection',
                userData.avcWiseSelection
              );

              that.$store.commit('updateAVCWiseInfo', userData.AVCWiseInfo);
              that.$store.commit(
                'updateShortlistedFunds',
                userData.shortlistedAvcFunds
              );
              that.$store.commit('setAVCEWiseFlag', userData.avcwiseuser);
            }

            if (userData.suezuser) {
              that.$store.commit('setSuezFlag', userData.suezuser);
              that.$store.commit(
                'updateDefaultReportIds',
                userData.defaultReportIds
              );
            }

            if (userData.pshuser) {
              that.$store.commit('setPshFlag', userData.pshuser);
              that.$store.commit('updatePshData', userData.pshData);
            }

            if (userData.firefighteruser) {
              that.$store.commit(
                'updateFireFighterStatus',
                userData.firefighteruser
              );
              that.$store.commit(
                'updatePensionDetails',
                userData.pensionDetails
              );
              that.$store.commit(
                'updateIncomeDetails',
                userData.incomeDetailsLastYear
              );
              that.$store.commit('updateFinalSalary', userData.finalSalary);
              that.$store.commit(
                'updatePensionSavings',
                userData.pensionSavings
              );
              that.$store.commit('updateTaxLiability', userData.taxLiability);
              that.$store.commit('updateMpaa', userData.mpaa);
            }
            that.$store.commit('updatePensionHistory', userData.pensionHistory);
            that.$store.commit('updateEmploymentInfo', userData.employmentInfo);
            that.$store.commit('updatePensionStatus', userData.pensionStatus);
            that.$store.commit('updateSignature', userData.signature);
            that.$store.commit('updateCreationTime', userData.createdAt);
            that.$store.commit('updateDocuments', userData.documents);
            that.$store.commit(
              'updateCheckoutPensions',
              userData.checkoutPensions
            );
            that.$store.commit(
              'updateCheckoutSessionId',
              userData.checkoutSessionId
            );
            that.$store.commit('updatePaidPensions', userData.paidPensions);
            that.$store.commit('authUser', true);

            const notificationsData = await getNotificationObjData(
              that.$store.state.user.documentId
            );

            this.$gtag.set({ user_id: that.$store.state.user.documentId });
            Sentry.setUser({ email: user.email });

            if (userData.firefighteruser) {
              that.$router.push({
                path: '/onboarding/pension-details',
                name: 'onboarding-pension-details'
              });
            }

            if (userData.avcwiseuser) {
              const calendlyWidgetElem = document.getElementById(
                'calendly-widget'
              );
              if (calendlyWidgetElem) {
                calendlyWidgetElem.classList.remove('d-none');
              }
              if (!that.$store.state.user.avcWiseSelection.retirementAge) {
                this.$router.push({
                  path: '/onboarding/fund-selection',
                  name: 'onboarding-fund-selection'
                });
                that.loading = false;
                return;
              } else if (
                this.$router.history.current.fullPath.endsWith(
                  'personal-info'
                ) ||
                this.$router.history.current.fullPath.endsWith('login')
              ) {
                this.$router.push({
                  path: '/dashboard/landing',
                  name: 'dashboard-landing'
                });
                that.loading = false;
                return;
              }
            }
            that.$store.commit('updateNotifications', notificationsData);
            this.loading = false;
          } catch (err) {
            this.loading = false;
            await logOut(this);
          }
        }
      } else {
        authFlag = false;
        this.$store.commit('authUser', false);
        this.loading = false;
        const routeUrl = this.$router.history.current.fullPath.replace(
          /\/+$/,
          ''
        );
        const routerString = routeUrl.split('/');
        const urlList = [
          'login',
          'login-error',
          'personal-info',
          'reset-password'
        ];
        if (!urlList.includes(routerString[routerString.length - 1])) {
          if (this.$productName == 'agewage-avcwise') {
            this.$router.push({
              path: '/onboarding/redeem-voucher',
              name: 'onboarding-redeem-voucher'
            });
          } else {
            this.$router.push({
              path: '/onboarding/personal-info',
              name: 'onboarding-personal-info'
            });
          }
        }
      }
    });
  }
};
</script>

<style lang="scss">
.gradient-purple {
  background-image: linear-gradient(to top, #8256fe, #6a53fa);
}

.default-dark {
  color: #0e0c23;
}

.button {
  font-size: $font-small;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.23;
  letter-spacing: 0.78px;
}

.btn-yellow {
  background-color: theme('colors.yellow');
}

.bold-300 {
  font-weight: 300;
}
.bold-400 {
  font-weight: 400;
}
.bold-600 {
  font-weight: 600;
}
.bold-700 {
  font-weight: 700;
}

.bold-800 {
  font-weight: 800;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

.button--green {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #3b8070;
  color: #3b8070;
  text-decoration: none;
  padding: 10px 30px;
}

.text-white {
  color: #fff;
}

.button--green:hover {
  color: #fff;
  background-color: #3b8070;
}

.button--grey {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #35495e;
  color: #35495e;
  text-decoration: none;
  padding: 10px 30px;
  margin-left: 15px;
}

.button--grey:hover {
  color: #fff;
  background-color: #35495e;
}

.x-label {
  letter-spacing: 0.025em;
  text-align: left;
  margin-bottom: 0.5rem;
  display: block;
}

.x-label3 {
  text-align: left;
  margin-bottom: 0.5rem;
  display: block;
  font-weight: 505;
}

.x-label1 {
  letter-spacing: 0.025em;
  text-align: center;
  margin-bottom: 0.5rem;
  display: block;
}

.x-label2 {
  letter-spacing: 0.025em;
  text-align: center;
  margin-bottom: 0.5rem;
  display: block;
  color: #5343e9;
  font-size: $font-extra-small;
  font-weight: bold;
}

.x-input {
  background-color: #f0f0f0;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75em;
  line-height: 1.25;
  display: block;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.x-input1 {
  background-color: gainsboro;
}

.x-button {
  width: 10rem;
  height: 3rem;
  font-size: $font-small;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.23;
  letter-spacing: 0.78px;
  color: #000000;
  background-color: #fdd032;
  border-radius: 9999px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.x-button1 {
  width: 10rem;
  height: 3rem;
  font-size: $font-small;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.23;
  letter-spacing: 0.78px;
  color: black;
  background-color: white;
  border-radius: 9999px;
  border-color: black;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  border: solid;
  border-width: 1px;
}
.x-input2 {
  background-color: #f0f0f0;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75em;
  line-height: 1.25;
  display: block;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.x-icon {
  height: 20px;
  width: 20px;
  display: block;
  /* Other styles here */
}

.div.row {
  display: inline-block;
}

.x-bordered {
  width: 100px;
  height: 10px;
  padding: 20px;
  border: 1px solid#dcdcdc;
  border-radius: 8px;
}

.gray {
  background: #dcdcdc;
}

#wrapper1 {
  display: flex;
}

#left1 {
  flex: 0 0 42%;
}
#middle1 {
  flex: 0 0 15%;
}
#right1 {
  flex: 0 0 42%;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}
.icon {
  padding: 15px;
  color: #bdb9b9;
  min-width: 250px;
  text-align: right;
}

.input-field {
  width: 100%;
  padding: 10px;
  text-align: center;
}

.wrapper-date {
  background-color: #ededf0;
  border-radius: 0.25rem;
  text-align: right;
  padding: 10px;
  vertical-align: middle;
  height: 41px;
  flex: 1 0 42%;
}

.wrapper-button {
  text-align: left;
  padding: 0px;
  vertical-align: middle;
  height: 5px;
  flex: 0 0 42%;
}

#wrapper1 {
  display: flex;
}

#left1 {
  flex: 0 0 42%;
}
#middle1 {
  flex: 0 0 15%;
}
#right1 {
  flex: 0 0 42%;
}

#left2 {
  flex: 0 0 30%;
}
#middle2 {
  flex: 0 0 6%;
}
#right2 {
  flex: 0 0 64%;
}
.__nuxt-error-page {
  position: relative !important;
}
</style>
