<template>
  <div class="overflow-hidden">
    <v-app
      class="index-page"
      :class="
        (isLandingPage &&
          productName == PRODUCT_CODES.suez &&
          'b2b2c-header-bg') ||
          ''
      "
    >
      <component
        :is="navbarComponent"
        :is-landing-page="isLandingPage"
        :is-report-page="isReportPage"
        :is-login-page="isLoginPage"
        :is-help-page="isHelpPage"
      />
      <cube v-if="loading"> </cube>
      <div v-else>
        <nuxt />
        <component :is="footerComponent" />
      </div>
      <Snackbar />
    </v-app>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import { CubeSpinner } from 'vue-spinners';
import * as Sentry from '@sentry/browser';
import { logOut } from '~/plugins/utils';
import { getUserStatus, getNotificationObjData } from '~/plugins/apiFunctions';
import Footer from '~/components/Footer/Footer.vue';
import Snackbar from '~/components/Snackbar.vue';
import { PRODUCT_CODES } from '~/constants';

export default {
  components: {
    Footer,
    cube: CubeSpinner,
    Snackbar
  },
  data() {
    return {
      loading: true,
      productName: this.$productName,
      isLandingPage: this.$router.history.current.path === '/',
      isReportPage: this.$router.history.current.path.includes('report'),
      isHelpPage: this.$router.history.current.path == '/help',
      isLoginPage:
        this.$router.history.current.path.replace(/\/+$/, '') ==
        '/onboarding/login',
      PRODUCT_CODES
    };
  },
  computed: {
    navbarComponent() {
      const navbar =
        this.$productName == PRODUCT_CODES.suez
          ? 'SuezNavBar'
          : this.$productName == PRODUCT_CODES.avcwise
          ? 'AvcNavBar'
          : 'NavBar';
      return () => import(`~/components/Header/${navbar}.vue`);
    },
    footerComponent() {
      return this.$productName == PRODUCT_CODES.suez ? '' : 'Footer';
    }
  },
  watch: {
    $route() {
      this.rerenderPage();
    }
  },
  async mounted() {
    const that = this;
    let authFlag = false;
    this.$nuxt.$emit('loading', true);
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user && !authFlag) {
        const userData = await getUserStatus(user);
        if (userData) {
          this.$store.commit('authUser', true);
          if (
            (userData.suezuser && this.$productName !== PRODUCT_CODES.suez) ||
            (userData.pensionbeeuser &&
              this.$productName !== PRODUCT_CODES.pensionbee) ||
            (userData.avcwiseuser &&
              this.$productName !== PRODUCT_CODES.avcwise)
          ) {
            return;
          }
          if (
            !user.emailVerified &&
            !userData.pensionbeeuser &&
            !userData.suezuser &&
            !userData.avcwiseuser &&
            !userData.firefighteruser &&
            !userData.pshuser &&
            this.$productName !== PRODUCT_CODES.suez &&
            this.$productName !== PRODUCT_CODES.pensionbee &&
            this.$productName !== PRODUCT_CODES.firefighter &&
            this.$productName !== PRODUCT_CODES.avcwise &&
            this.$productName !== PRODUCT_CODES.psh
          ) {
            this.$router.push({
              path: '/verify-email',
              name: 'verify-email'
            });
          }

          // set other objects in the state like personal info and pensionhistory
          authFlag = true;
          try {
            that.$store.commit('updateDocumentId', userData.documentId);
            that.$store.commit('updatePersonalInfo', userData.personalInfo);

            if (userData.pensionbeeuser) {
              that.$store.commit(
                'updatePensionBeeStatus',
                userData.pensionbeeuser
              );
              that.$store.commit(
                'updatePensionBeeInfo',
                userData.pensionBeeInfo
              );
              that.$store.commit(
                'updateShortlistedFund',
                userData.shortlistedFund
              );
            }

            if (userData.avcwiseuser) {
              that.$store.commit('updateTourStatus', userData.tourCompleted);
              that.$store.commit(
                'updateAVCWiseSelection',
                userData.avcWiseSelection
              );
              that.$store.commit('updateAVCWiseInfo', userData.AVCWiseInfo);
              that.$store.commit(
                'updateShortlistedFunds',
                userData.shortlistedAvcFunds
              );
              that.$store.commit('setAVCEWiseFlag', userData.avcwiseuser);
            }

            if (userData.suezuser) {
              that.$store.commit('setSuezFlag', userData.suezuser);
              that.$store.commit(
                'updateDefaultReportIds',
                userData.defaultReportIds
              );
            }

            if (userData.pshuser) {
              that.$store.commit('setPshFlag', userData.pshuser);
              that.$store.commit('updatePshData', userData.pshData);
            }

            if (userData.firefighteruser) {
              that.$store.commit(
                'updateFireFighterStatus',
                userData.firefighteruser
              );
              that.$store.commit(
                'updatePensionDetails',
                userData.pensionDetails
              );
              that.$store.commit(
                'updatePensionSavings',
                userData.pensionSavings
              );
              that.$store.commit(
                'updateIncomeDetails',
                userData.incomeDetailsLastYear
              );
              that.$store.commit('updateFinalSalary', userData.finalSalary);
              that.$store.commit('updateTaxLiability', userData.taxLiability);
              that.$store.commit('updateMpaa', userData.mpaa);
            }

            that.$store.commit('updatePensionHistory', userData.pensionHistory);
            that.$store.commit('updateEmploymentInfo', userData.employmentInfo);
            that.$store.commit('updatePensionStatus', userData.pensionStatus);
            that.$store.commit('updateSignature', userData.signature);
            that.$store.commit('updateCreationTime', userData.createdAt);
            that.$store.commit('updateDocuments', userData.documents);
            that.$store.commit(
              'updateCheckoutPensions',
              userData.checkoutPensions
            );

            that.$store.commit(
              'updateCheckoutSessionId',
              userData.checkoutSessionId
            );
            that.$store.commit('updatePaidPensions', userData.paidPensions);

            that.$store.commit('authUser', true);

            const notificationsData = await getNotificationObjData(
              that.$store.state.user.documentId
            );
            this.$gtag.set({ user_id: that.$store.state.user.documentId });
            Sentry.setUser({ email: user.email });
            that.$store.commit('updateNotifications', notificationsData);
            if (userData.avcwiseuser) {
              const calendlyWidgetElem = document.getElementById(
                'calendly-widget'
              );
              if (calendlyWidgetElem) {
                calendlyWidgetElem.classList.remove('d-none');
              }
            }
            this.$nuxt.$emit('loading', false);
          } catch (err) {
            this.$nuxt.$emit('loading', false);
            await logOut(this);
          }
        }
      } else {
        this.$store.commit('authUser', false);
        this.$nuxt.$emit('loading', false);
      }
    });
    that.loading = false;
  },
  methods: {
    rerenderPage() {
      this.isLandingPage = this.$router.history.current.path === '/';
      this.isReportPage = this.$router.history.current.path.includes('report');
      this.isLoginPage =
        this.$router.history.current.path.replace(/\/+$/, '') ==
        '/onboarding/login';
      this.isHelpPage = this.$router.history.current.path == '/help';
    }
  }
};
</script>
<style>
.index-page.b2b2c-header-bg {
  background-image: url('../assets/main-bg@3x.png') !important;
}
.landing-bottom-img {
  position: absolute;
  bottom: 0;
}
</style>
