import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _96262b6c = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _f7f76dae = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _54985472 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _e96c2c0e = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _462e6b30 = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _416da1b8 = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _6bdb5e57 = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _005a36e3 = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _e1d09648 = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _448347bb = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _fac10790 = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _08f40dc0 = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _19394b63 = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _24375405 = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _14e69189 = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _1385a5b0 = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _ff8372b8 = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _52018fa2 = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _b6323502 = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _17cb5270 = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _7d67e794 = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _43f6dc19 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _0dd1bd28 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _11aca5d4 = () => interopDefault(import('../pages/onboarding/final-salary.vue' /* webpackChunkName: "pages/onboarding/final-salary" */))
const _353eac17 = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _3944cfb4 = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _25f82621 = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _546d1ea4 = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _47072440 = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _9bee9e52 = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _b4be5a50 = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _a9be5ae0 = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _cee14328 = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _2754f680 = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _c9c50a3e = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _2426fe8f = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _7c346676 = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _5d3fb187 = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _77da9164 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _78c7ab1e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _96262b6c,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _f7f76dae,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _54985472,
    name: "help"
  }, {
    path: "/verify-email",
    component: _e96c2c0e,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _462e6b30,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _416da1b8,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _6bdb5e57,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _005a36e3,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _e1d09648,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _448347bb,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _fac10790,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _08f40dc0,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _19394b63,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _24375405,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _14e69189,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _1385a5b0,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _ff8372b8,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _52018fa2,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _b6323502,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _17cb5270,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _7d67e794,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _43f6dc19,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/employment-info",
    component: _0dd1bd28,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/final-salary",
    component: _11aca5d4,
    name: "onboarding-final-salary"
  }, {
    path: "/onboarding/fund-selection",
    component: _353eac17,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _3944cfb4,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _25f82621,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _546d1ea4,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _47072440,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _9bee9e52,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/personal-info",
    component: _b4be5a50,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _a9be5ae0,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _cee14328,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _2754f680,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _c9c50a3e,
    name: "onboarding-suez-info"
  }, {
    path: "/personal/account",
    component: _2426fe8f,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _7c346676,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _5d3fb187,
    name: "personal-retirement"
  }, {
    path: "/blog/:slug?",
    component: _77da9164,
    name: "blog-slug"
  }, {
    path: "/",
    component: _78c7ab1e,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
